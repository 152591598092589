.field {
    &--inline {
        @mixin main-grid;
    }

    &--type_hidden {
        display: none;
    }

    &__label {
        display: block;
        margin: 0 0 8px 8px;
        color: $color-lightblack;
        font-size: 18px;
        font-weight: medium;
    }

    &--required &__label::after {
        content: '*';
        margin-left: 4px;
        color: $color-red;
    }

    &--inline &__label {
        grid-column: 1/4;
        align-self: flex-start;
        margin: 17px 0 0;
    }

    &--inline&--type_boolean &__label {
        display: none;
    }

    &__input {
        /* dummy */
    }

    &__comment {
        position: relative;
    }

    &__tooltip {
        /* dummy */
    }

    &--type_document &__input {
        display: flex;
        justify-content: space-between;

        input {
            width: 49%;
        }
    }

    &--type_person &__input {
        display: flex;
        justify-content: space-between;

        input {
            width: 32%;
        }
    }

    &--type_house &__input {
        display: flex;
        justify-content: space-between;

        select,
        input {
            width: 49%;
        }
    }

    &--type_verify &__input {
        display: flex;
        justify-content: space-between;

        input {
            width: 24%;
        }

        button {
            width: 34%;
        }
    }

    &--type_username &__input {
        input {
            @mixin input--icon passport;
        }
    }

    &--type_password &__input {
        input {
            @mixin input--icon lock;
        }
    }


    &--inline &__input {
        grid-column: 4/13;
    }

    &--inline&--type_boolean &__input {
        grid-column: 1/11;
    }

    &--inline &__comment {
        grid-column: 10/13;
        grid-row-start: 2;
        grid-row-end: 3;
    }
}
