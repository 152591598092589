.tooltip {
    position: relative;
    padding: 6px 12px;
    margin-top: 2px;
    border-radius: 3px;
    background-color: $color-marengo;
    color: $color-white;
    font-size: 12px;
    font-weight: medium;

    &::before {
        content: '';
        position: absolute;
        right: 100%;
        top: 6px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid $color-marengo;
    }
}
