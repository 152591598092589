.entity-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid $color-gray;
    border-radius: 4px;

    &__head {
        flex-grow: 0;
    }

    &__body {
        flex-grow: 1;
    }

    &__foot {
        flex-grow: 0;
    }

    &__header {
        padding: 24px;
        font-size: 16px;
        font-weight: semibold;
    }

    &__main {
        padding: 0 24px 16px;
    }

    &__attr {
        & + & {
            margin-top: 8px;
        }

        &-label {
            display: inline;
            margin-right: 4px;
            font-weight: semibold;
        }

        &-value {
            display: inline;
            font-weight: medium;
            color: $color-darkgray;
        }
    }

    &__section {
        & + & {
            margin-top: 16px;
            border-top: 1px solid $color-gray;
            padding-top: 16px;
        }
    }

    &__footer {
        border-top: 1px solid $color-gray;
        padding: 16px 24px 16px;
    }

    &__panel {
        border-top: 1px solid $color-gray;
        padding: 16px 24px 16px;

        &-item {
            text-align: center;

            & + & {
                margin-top: 8px;
            }
        }
    }
}
