@define-mixin __input {
    width: 100%;
    height: 56px;
    padding: 15px 16px;
    border: 1px solid $color-darkyellow;
    border-radius: 10px 0;
    font-size: 18px;
    line-height: 1.33;
    font-weight: normal;
    font-style: normal;
    color: $color-lightblack;
    background-color: rgba($color-lightblack, 0.005);
}

@define-mixin input {
    @mixin __input;

    &::placeholder {
        color: $color-gray;
    }

    &.is-valid {
        border-color: $color-green;
        background-color: rgba($color-green, 0.03);
    }

    &.is-invalid {
        border-color: $color-yellow;
        background-color: rgba($color-yellow, 0.03);
    }

    &.is-error {
        border-color: $color-lightred;
        background-color: rgba($color-lightred, 0.03);
        color: $color-lightred;
    }

    &:focus {
        border-color: $color-blue;
        background-color: rgba($color-blue, 0.03);
    }

    &:disabled {
        background-color: rgba($color-lightblack, 0.05);
        color: $color-gray;
        cursor: not-allowed;
    }

    &[readonly] {
        border-color: $color-lightgray;
        background-color: rgba($color-lightblack, 0.02);
        color: $color-darkgray;
        cursor: default;
    }
}

@define-mixin input--icon $icon {
    padding-left: 56px;
    background-image: svg-load('assets/img/icon/$(icon).svg', fill=$color-gray);
    background-position: 16px 50%;
    background-repeat: no-repeat;

    &.is-valid {
        background-image: svg-load('assets/img/icon/$(icon).svg', fill=$color-green);
    }

    &.is-invalid {
        background-image: svg-load('assets/img/icon/$(icon).svg', fill=$color-yellow);
    }

    &.is-error {
        background-image: svg-load('assets/img/icon/$(icon).svg', fill=$color-lightred);
    }

    &:focus {
        background-image: svg-load('assets/img/icon/$(icon).svg', fill=$color-blue);
    }

    &:disabled {
        background-image: svg-load('assets/img/icon/$(icon).svg', fill=$color-lightgray);
    }

    &[readonly] {
        background-image: svg-load('assets/img/icon/$(icon).svg', fill=$color-lightgray);
    }
}

@define-mixin select {
    @mixin input;

    padding-right: 40px;
    background: svg-load('assets/img/element/select.svg', fill=$color-gray) calc(100% - 16px) 50% no-repeat;
    appearance: none;

    &::-ms-expand {
        display: none;
    }

    &.is-valid {
        background-image: svg-load('assets/img/element/select.svg', fill=$color-green);
    }

    &.is-invalid {
        background-image: svg-load('assets/img/element/select.svg', fill=$color-yellow);
    }

    &.is-error {
        background-image: svg-load('assets/img/element/select.svg', fill=$color-lightred);
    }

    &:focus {
        background-image: svg-load('assets/img/element/select.svg', fill=$color-blue);
    }

    &:disabled {
        background-image: svg-load('assets/img/element/select.svg', fill=$color-lightgray);
    }

    &[readonly] {
        background-image: svg-load('assets/img/element/select.svg', fill=$color-lightgray);
    }
}

@define-mixin autocomplete {
    .vs__dropdown-toggle {
        @mixin __input;

        height: auto;
        min-height: 56px;
        background: svg-load('assets/img/element/select.svg', fill=$color-gray) calc(100% - 16px) 50% no-repeat;
    }

    .vs__clear {
        width: 24px;
        height: 100%;
        margin: 0;
        padding: 0;
        background: svg-load('assets/img/icon/x.svg', fill=$color-gray) 50% 50% no-repeat;

        &:hover {
            background-image: svg-load('assets/img/icon/x.svg', fill=$color-black);
        }

        & * {
            display: none;
        }
    }

    .vs__selected-options {
        overflow: hidden;
        padding: 0;
    }

    .vs__actions {
        padding: 0 12px 0 4px;
    }

    .vs__open-indicator {
        display: none;
    }

    .vs__selected {
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .vs__selected,
    input[type='search'],
    input[type='search']:focus {
        margin: 0;
        padding: 0;
        border: none;
        font: inherit;
        color: inherit;
    }

    input::placeholder {
        color: $color-gray;
    }

    &.is-valid {
        .vs__dropdown-toggle {
            border-color: $color-green;
            background-color: rgba($color-green, 0.03);
            background-image: svg-load('assets/img/element/select.svg', fill=$color-green);
        }
    }

    &.is-invalid {
        .vs__dropdown-toggle {
            border-color: $color-yellow;
            background-color: rgba($color-yellow, 0.03);
            background-image: svg-load('assets/img/element/select.svg', fill=$color-yellow);
        }
    }

    &.is-error {
        .vs__dropdown-toggle {
            border-color: $color-lightred;
            background-color: rgba($color-lightred, 0.03);
            background-image: svg-load('assets/img/element/select.svg', fill=$color-lightred);
            color: $color-lightred;
        }
    }

    &.vs--open {
        .vs__dropdown-toggle {
            border-color: $color-blue;
            background-color: rgba($color-blue, 0.03);
            background-image: svg-load('assets/img/element/select.svg', fill=$color-blue);
        }
    }

    &.vs--disabled {
        .vs__dropdown-toggle {
            background-color: rgba($color-lightblack, 0.05);
            background-image: svg-load('assets/img/element/select.svg', fill=$color-lightgray);
            color: $color-gray;
        }

        input,
        .vs__open-indicator {
            background-color: transparent;
        }
    }

    &.vs--readonly {
        .vs__dropdown-toggle {
            background-color: rgba($color-lightblack, 0.02);
            background-image: svg-load('assets/img/element/select.svg', fill=$color-lightgray);
            color: $color-gray;
        }

        input,
        .vs__open-indicator {
            background-color: transparent;
        }
    }
}

@define-mixin textarea {
    @mixin input;

    height: 118px;
}

@define-mixin __input-box {
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;

    & + label {
        position: relative;
        display: block;
        padding-left: 26px;
        font-size: 18px;
        line-height: 1.2;
        font-weight: medium;
        font-style: normal;
        color: $color-lightblack;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 2px;
            width: 18px;
            height: 18px;
            border: 1px solid $color-darkred;
        }

        &::after {
            content: '';
            display: none;
        }

        &:hover::before {
            box-shadow: 0 0 4px -2px $color-lightblack;
        }
    }

    &:checked + label {
        &::before {
            border-color: $color-darkred;
            background-color: $color-darkred;
        }

        &::after {
            display: block;
        }

        &:hover::before {
            box-shadow: 0 0 4px -2px $color-darkred;
        }
    }

    &:disabled + label {
        color: $color-gray;
        cursor: not-allowed;

        &::before {
            opacity: 0.5;
        }

        &:hover::before {
            box-shadow: none;
        }
    }

    &[readonly] + label {
        color: $color-darkgray;
        cursor: default;

        &:hover::before {
            box-shadow: none;
        }
    }
}

@define-mixin checkbox {
    @mixin __input-box;

    & + label {
        &::before {
            border-radius: 1px;
        }

        &::after {
            position: absolute;
            left: 7px;
            top: 10px;
            width: 10px;
            height: 5px;
            border-bottom: 2px solid $color-white;
            border-left: 2px solid $color-white;
            transform: rotate(-45deg);
            transform-origin: 0 100% 0;
        }
    }
}

@define-mixin radiobox {
    @mixin __input-box;

    & + label {
        &::before {
            border-radius: 50%;
        }

        &::after {
            position: absolute;
            left: 5px;
            top: 7px;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $color-white;
        }
    }
}

@define-mixin button {
    display: inline-block;
    vertical-align: middle;
    height: 49px;
    box-sizing: border-box;
    border-radius: 10px 0;
    padding: 7px 40px 10px;
    margin: auto 10px;
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        background: #C4C4C4;
    }

    &:active:not(:disabled) {
        padding: 9px 38px 10px 42px;
    }
    &:last-child {
        margin-right: 0;
    }
}

@define-mixin button--color $bg-color, $label-color {
    $shadow-color: color($bg-color shade(24%));
    $border-color: color($bg-color shade(12%));

    @mixin gradient-darken bottom, $bg-color, 8%;

    @if $bg-color == $color-white {
        box-shadow: inset 0 -1px 0 0 $shadow-color, inset 0 1px 0 0 $border-color, inset 1px 0 0 0 $border-color, inset -1px 0 0 0 $border-color;
    } @else {
        box-shadow: inset 0 -1px 0 0 $shadow-color;
    }

    color: $label-color;

    &:active:not(:disabled) {
        @if $bg-color == $color-white {
            box-shadow: inset 0 1px 0 0 $shadow-color, inset 0 -1px 0 0 $border-color, inset 1px 0 0 0 $border-color, inset -1px 0 0 0 $border-color;
        } @else {
            box-shadow: inset 0 1px 0 0 $shadow-color;
        }
    }

    &:hover:not(:disabled) {
        @mixin gradient-darken top, $bg-color, 8%;
    }
}

@define-mixin inpton {
    @mixin __input;

    display: inline-block;
    vertical-align: middle;
    border: none;
    padding: 15px 16px 17px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.4;
    }

    &:active:not(:disabled) {
        padding: 17px 15px 15px 17px;
    }
}

@define-mixin inpton--color $bg-color, $label-color {
    @mixin button--color $bg-color, $label-color;
}
