.bank-select {
    &__list {
        @mixin banks-grid, 3, 1fr;

        margin-bottom: -24px;
    }

    &__item {
        margin-bottom: 24px;
        border: 2px solid $color-lightgray;
        border-radius: 20px 0;
        padding: 32px 16px 16px;
        cursor: pointer;

        &:hover {
            box-shadow: 0 0 4px 0 $color-lightgray;
        }

        &.is-active {
            border-color: $color-darkgray;
            box-shadow: inset 0 0 0 2px $color-darkgray;
        }


    }

    &__icon {
        height: 130px;
        margin-bottom: 16px;
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        opacity: 0.5;
    }

    &__item.is-active &__icon {
        opacity: 1;
    }

    &__name {
        font-size: 18px;
        font-weight: medium;
        text-align: center;
    }

    &__empty {
        /* dummy */
    }
}
