.loader {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity .3s ease;

    &,
    & * {
        cursor: wait !important;
    }

    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-lightblack, 0.25);
    }

    &__container {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 400px;
        height: 400px;
        margin: -200px 0 0 -200px;
        border-radius: 20px 0;
        box-shadow: 0 0 4px 0 rgba($color-lightblack, 0.25);
        background-color: $color-white;
        overflow: auto;
        transition: all .3s ease;
    }

    &__block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 100%;
    }

    &__spinner {
        /* dummy */
    }

    &__label {
        margin-top: 40px;
        color: $color-lightblack;
        font-size: 26px;
        text-align: center;
    }

    /* Vue.js transition loader */
    &.loader-enter,
    &.loader-leave-active {
        opacity: 0;

        &,
        & * {
            cursor: initial !important;
        }
    }
}
