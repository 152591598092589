.modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: opacity .3s ease;

    &__bg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color-lightblack, 0.35);
    }

    &__container {
        $modal-height: 480px;

        position: absolute;
        left: 50%;
        top: 50%;
        width: $modal-width;
        height: $modal-height;
        margin: calculate(-$modal-height/2) 0 0 calculate(-$modal-width/2);
        border-radius: 20px 0;
        box-shadow: 0 0 4px 0 rgba($color-lightblack, 0.25);
        background-color: $color-white;
        overflow: auto;
        transition: all .3s ease;
    }

    &__block {
        @mixin modal-padding;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        min-height: 100%;
        padding-top: 24px;
        padding-bottom: 24px;
    }

    &__head {
        flex-shrink: 0;
        margin-bottom: 24px;
    }

    &__body {
        flex-grow: 1;
    }

    &__date {
        flex-shrink: 0;
        font-size: 10px;
        color: $color-darkgray;
        align-self: self-end;
        margin-bottom: -10px;
        margin-top: 5px;
    }

    &__foot {
        flex-shrink: 0;
        margin-top: 24px;
    }

    &__header {
        /* dummy */
    }

    &__content {
        /* dummy */
    }

    &__footer {
        /* dummy */
    }

    &__panel {
        @mixin modal-grid;
    }

    &__prev {
        grid-column: 1/5;
    }

    &__next {
        grid-column: 5/9;
    }

    &__submit {
        grid-column: 1/9;
    }

    /* Vue.js transition */
    &.modal-enter-active,
    &.modal-leave-active {
        transition: all .5s;
    }

    &.modal-enter,
    &.modal-leave-active {
        transition: opacity .5s;
        opacity: 0;
    }

    &.modal-enter &__container,
    &.modal-leave-active &__container {
        transform: scale(1.1);
    }
}
