.entity-list {
    &__list {
        @mixin main-grid;

        margin-bottom: -24px;
    }

    &__item {
        margin-bottom: 24px;

        &:nth-child(3n+1) {
            grid-column: 1/5;
        }

        &:nth-child(3n+2) {
            grid-column: 5/9;
        }

        &:nth-child(3n+3) {
            grid-column: 9/13;
        }
    }

    &__empty {
        /* dummy */
    }
}
