@define-mixin root-grid {
    display: grid;
    grid-template-columns: $main-width;
    grid-column-gap: 0;
    grid-template-rows: 76px auto;
    justify-content: center;
    max-width: $main-width;
    margin-left: auto;
    margin-right: auto;
}


@define-mixin main-grid {
    display: grid;
    grid-template-columns: repeat($main-col-count, $main-col-width);
    grid-column-gap: $main-col-gap;
    grid-template-rows: auto;
    justify-content: center;
}

@define-mixin banks-grid, $col-count, $col-width {
    display: grid;
    grid-template-columns: repeat($col-count, $col-width);
    grid-column-gap: $main-col-gap;
    grid-template-rows: auto;
    justify-content: center;
}

@define-mixin main-margin {
    margin-left: $main-margin;
    margin-right: $main-margin;
}

@define-mixin main-padding {
    padding-left: $main-margin;
    padding-right: $main-margin;
}

@define-mixin modal-grid {
    display: grid;
    grid-template-columns: repeat($modal-col-count, $modal-col-width);
    grid-column-gap: $modal-col-gap;
    grid-template-rows: auto;
    justify-content: center;
}

@define-mixin modal-margin {
    margin-left: $modal-margin;
    margin-right: $modal-margin;
}

@define-mixin modal-padding {
    padding-left: $modal-margin;
    padding-right: $modal-margin;
}
