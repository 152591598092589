.form {
    &__field {
        & + & {
            margin-top: 16px;
        }
    }

    &__fieldset {
        & + & {
            margin-top: 32px;
        }
    }

    &__header {
        /* dummy */
    }

    &__message {
        margin-top: 16px;
        padding: 4px 16px;
        font-size: 16px;
        line-height: 1.5;
        color: $color-lightblack;
        background-color: rgba($color-lightblack, 0.02);

        &--error {
            color: $color-lightred;
            background-color: rgba($color-lightred, 0.03);
        }

        &--warning {
            background-color: rgba($color-yellow, 0.03);
        }

        &--info {
            background-color: rgba($color-blue, 0.03);
        }
    }

    &__remark {
        margin-top: 24px;
        color: $color-darkgray;
        font-size: 16px;
        line-height: 1.5;
    }

    &__panel {
        margin-top: 24px;
    }
}
