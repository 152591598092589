/* This stylesheet generated by Transfonter (https://transfonter.org) on August 20, 2017 8:37 PM */

@font-face {
	font-family: 'Tahoma';
	src: url('assets/fianit_workplace/fonts/Tahoma.eot');
	src: local('Tahoma'),
		url('assets/fianit_workplace/fonts/Tahoma.eot?#iefix') format('embedded-opentype'),
		url('assets/fianit_workplace/fonts/Tahoma.woff') format('woff'),
		url('assets/fianit_workplace/fonts/Tahoma.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Tahoma';
	src: url('assets/fianit_workplace/fonts/Tahoma-Bold.eot');
	src: local('Tahoma Bold'), local('Tahoma-Bold'),
		url('assets/fianit_workplace/fonts/Tahoma-Bold.eot?#iefix') format('embedded-opentype'),
		url('assets/fianit_workplace/fonts/Tahoma-Bold.woff') format('woff'),
		url('assets/fianit_workplace/fonts/Tahoma-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}