.header {
    display: grid;
    grid-template-columns: 55px auto;
    grid-template-rows: auto;

    &__logo {
        height: 50px;
        width: 50px;
        background: svg-load('assets/fianit_workplace/img/logo2.svg') no-repeat;
        background-size: 50px 50px;
    }
    &__button {
        justify-self: end;
    }
}