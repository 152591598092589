.app {
    position: relative;
    height: 100%;

    &__transfer {
        height: 100%;
    }
    &__bank,
    &__banks {
        height: 100%;
    }
    &__block {
        @mixin root-grid;

        position: relative;
        height: 100%;
    }

    &__header {
        background-color: $color-darkred;
        color: $color-white;
        position: relative;
        padding: 12px 60px 12px 60px;
    }
    &__main {
        position: relative;
        grid-column: 1/3;
        height: auto;
    }
}
